import { NavLink, isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { Image } from '@storefront/ui-components/shared/images/Image';
import { type FC, useMemo } from 'react';

export const GenericError: FC = () => {
  const error = useRouteError();

  const errorComponent = useMemo(() => {
    if (isRouteErrorResponse(error)) {
      return (
        <pre id="#error_info">
          {error.status} {error.statusText}
          {error.data}
        </pre>
      );
    }
    if (error instanceof Error) {
      return (
        <pre id="#error_info">
          {error.message}
          {error.stack}
        </pre>
      );
    }
    return null;
  }, [error]);

  return (
    <section className="flex items-center min-h-[620px] justify-center flex-col px-6 text-center">
      <h1 className="text-2xl mb-4 font-bold uppercase">Oops!</h1>
      <p className="mb-4 font-semibold my-3">It looks like there might be a connection issue.</p>
      <p className="mb-4">Please check your internet settings and try again.</p>
      <NavLink to="/" className="underline">
        Or browse the latest auctions here.
      </NavLink>
      <Image containerClassName="mt-6 w-10/12 max-w-[500px]" src="/assets/generic-error.png" alt="Generic error" />
      {errorComponent && <div className="hidden">{errorComponent}</div>}
    </section>
  );
};
